<!-- @format -->

<template>
  <div class="inhalt-overview" :class="$mq">
    <div class="grass" :class="$mq">
      <div class="tt desktop-trailer" :class="$mq" id="desktop-trailer">
        <img :src="require(`@/assets/images/` + $config.tt)" alt />
      </div>
      <div class="right-side">
        <div class="packshot-top" :class="$mq">
          <a
            href="https://www.amazon.de/Die-Vergesslichkeit-Eichh%C3%B6rnchen-Emilia-Sch%C3%BCle/dp/B09FC5VPJ6/ref=sr_1_2?__mk_de_DE=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=2R5G4C2CD4CQM&dchild=1&keywords=die+vergesslichkeit+der+eichh%C3%B6rnchen&qid=1635243344&qsid=262-1034553-2302661&sprefix=die+vergesslic%2Caps%2C272&sr=8-2&sres=B099ZY5ZVD%2CB09FC5VPJ6%2CB01K8Y6XC6%2CB019ZS57SW%2C3865662676%2CB07N2Y2HPM%2CB07PKSFJZ6%2CB07JZK4QSG%2CB0100YUSCK%2CB08NY7YNJ4%2C3836960303%2C3945302137%2C3948638284%2C3981626273%2C1079725784%2C3969670683&srpt=DOWNLOADABLE_MOVIE"
            target="_blank"
            ><img src="@/assets/images/packshot_top.png"
          /></a>
        </div>
        <div class="itunes-top" :class="$mq">
          <a
            href="https://itunes.apple.com/ch/movie/die-vergesslichkeit-der-eichh%C3%B6rnchen/id1574867657"
            target="_blank"
          >
            <img src="@/assets/images/itunes_top.png"
          /></a>
        </div>
        <div class="amazon-top" :class="$mq">
          <a
            href="https://www.amazon.de/Die-Vergesslichkeit-Eichh%C3%B6rnchen-Emilia-Sch%C3%BCle/dp/B09FC5VPJ6/ref=sr_1_2?__mk_de_DE=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=2R5G4C2CD4CQM&dchild=1&keywords=die+vergesslichkeit+der+eichh%C3%B6rnchen&qid=1635243344&qsid=262-1034553-2302661&sprefix=die+vergesslic%2Caps%2C272&sr=8-2&sres=B099ZY5ZVD%2CB09FC5VPJ6%2CB01K8Y6XC6%2CB019ZS57SW%2C3865662676%2CB07N2Y2HPM%2CB07PKSFJZ6%2CB07JZK4QSG%2CB0100YUSCK%2CB08NY7YNJ4%2C3836960303%2C3945302137%2C3948638284%2C3981626273%2C1079725784%2C3969670683&srpt=DOWNLOADABLE_MOVIE"
            target="_blank"
          >
            <img src="@/assets/images/amazon_top.png"
          /></a>
        </div>
      </div>
      <div class="kinostart-top" :class="$mq">
        <div class="text" :class="$mq">
          Ab 13.01.2022 auf DVD <br /><span :class="$mq"
            >Ab 23.12.2021 als digitaler Download</span
          >
        </div>
      </div>
      <div class="trailer" :class="$mq">
        <div v-if="$config.youtube.display">
          <div @click="ytVideo" class="open-trailer" style="position: relative">
            <video src="@/assets/videos/sizzle.mp4" loop muted autoplay></video>
            <div class="trailer-misc">
              <img
                :style="[
                  ratio < 1.73
                    ? {
                        width: '140px',
                      }
                    : {
                        width: '170px',
                      },
                ]"
                class="play-btn"
                src="@/assets/images/trailer/play.png"
                alt
              />
              <div class="trailer-text">Trailer ansehen</div>
            </div>
          </div>
        </div>
        <!--<img src="@/assets/images/grass.png" />-->
      </div>
      <div class="cast-inhalt" :class="$mq">
        <img src="@/assets/images/cast.png" />
      </div>
    </div>
    <div class="inhalt desktop-inhalt" :class="$mq" id="desktop-inhalt">
      <div class="cast-eich" :class="$mq">
        <img src="@/assets/images/eich_inhalt.png" />
      </div>
      <div class="inhalt__wrapper" :class="$mq" id="desktop-inhalt">
        <div class="inhalt-inhalt">
          <div class="inhalt-texte">
            <div class="inhalt-cites" :class="$mq">
              <img src="@/assets/images/inhalt_cline.png" />
            </div>

            <div class="texte">
              <div class="text-1" :class="$mq">
                Um Geld für ihre Familie daheim in der Ukraine zu verdienen,
                kommt Marija <span>(Emilia Schüle)</span> nach Deutschland. In
                seiner alten Villa soll sie sich rund um die Uhr um den an
                Demenz erkrankten Curt
                <span>(Günther Maria Halmer)</span> kümmern. Landet aber im
                zwischenmenschlichen Minenfeld einer deutschen Familie – deren
                fragile Dynamik sie nun, ganz unfreiwillig, gehörig
                durcheinander bringt. Curts Tochter Almut
                <span>(Anna Stieblich)</span>, die in den letzten Jahren für ihn
                gesorgt hat, fühlt sich von ihrem Vater nicht wertgeschätzt, ihr
                Kontrollwahn hält sie aber davon ab loszulassen. Curt wiederum
                beginnt, Marija für seine früh verstorbene Frau Marianne zu
                halten, und wähnt sich zunehmend in längst vergangenen Zeiten.
                Marija lässt sich auf das skurrile Spiel ein und wird für Curt
                immer mehr zu Marianne. Zurückversetzt in das Lebensgefühl der
                70er Jahre entwickelt der alte Herr eine ganz neue, ungeahnte
                Lebensfreude – wäre da nicht auch eine tief vergrabene,
                schmerzliche Erinnerung. Als dann auch noch Almuts Bruder
                Philipp <span>(Fabian Hinrichs)</span> auftaucht, der bisher so
                wenig wie möglich mit Curt zu tun haben wollte, nun aber die
                Nähe zur bildhübschen Marija sucht, beginnen die Dinge komplett
                aus dem Ruder zu laufen.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { EventBus } from '@/plugins/event-bus.js'
  export default {
    props: {
      kinostart: {
        type: String,
        default: 'Releaseline',
      },
      ratio: {
        type: Number,
        default: 1.78,
      },
      trailer: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      isFixed() {
        return this.position === 'fixed'
      },
    },
    methods: {
      ytVideo() {
        EventBus.$emit('open-yt')
      },
      kinoliste() {
        EventBus.$emit('open-kinoliste')
      },
    },
  }
</script>

<style lang="scss">
  .inhalt-overview {
    margin-top: -450px;
    display: flex;
    flex-direction: column;
    &.xxxxl {
      margin-top: -680px;
    }
    &.xxxl {
      margin-top: -550px;
    }
    &.xl {
      .inhalt-overview {
        margin-top: -20px;
      }
    }
    .grass {
      width: 100%;
      z-index: 2;
      $self: &;
      background-position: center top;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url('~@/assets/images/grass_2.png');
      overflow: hidden;
      position: relative;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      align-items: center;
      .tt {
        margin-top: 230px;
        margin-right: 200px;
        width: 30%;
        z-index: 10;
        &.lg {
          width: 35%;
        }
        img {
          width: 100%;
        }
      }
      .right-side {
        position: absolute;
        width: 20%;
        height: 30%;
        top: 200px;
        right: 40%;
        transform: translateX(65%);
        .packshot-top {
          position: absolute;
          width: 85%;
          right: 40%;
          transform: translateX(35%);
          &.xxxl,
          &.xxxxl {
            right: 35%;
          }
          img {
            width: 100%;
          }
        }
        .itunes-top {
          position: absolute;
          bottom: 5px;
          width: 35%;
          right: 5%;
          transition: transform 0.2s;
          &:hover {
            transform: scale(1.05);
          }
          &.xxxl,
          &.xxxxl {
            right: -3%;
            bottom: -20px;
          }
          &.xl {
            bottom: 25px;
          }
          &.lg {
            bottom: 40px;
            width: 50%;
            right: -23%;
          }
          img {
            width: 100%;
          }
        }
        .amazon-top {
          position: absolute;
          bottom: 5px;
          width: 35%;
          right: 42%;
          transition: transform 0.2s;
          &:hover {
            transform: scale(1.05);
          }
          &.xxxl,
          &.xxxxl {
            right: 35%;
            bottom: -20px;
          }
          &.xl {
            bottom: 25px;
          }
          &.lg {
            bottom: 40px;
            width: 50%;
            right: 30%;
          }
          img {
            width: 100%;
          }
        }
      }
      .cast-inhalt {
        margin-bottom: 4rem;
        &.xxxl,
        &.xxxxl {
          width: 38%;
          img {
            width: 100%;
          }
        }
        &.xxl,
        &.xl {
          width: 38%;
          img {
            width: 100%;
          }
        }
        &.lg {
          width: 50%;
          img {
            width: 100%;
          }
        }
      }
      .trailer {
        pointer-events: auto;
        margin: 2% 0;
        position: relative;
        width: 50%;
        border: 19px solid white;
        &.lg {
          border: 8px solid white;
          width: 60%;
        }
        .trailer-text {
          text-align: center;
          font-weight: 400;
          color: white;
          text-transform: uppercase;
          margin-top: 1rem;
          $min_width: 1024px;
          $max_width: 2560px;
          $min_font: 16px;
          $max_font: 30px;
          @include fluid-type($min_width, $max_width, $min_font, $max_font);
        }
        cursor: pointer;
        .trailer-misc {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 111;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          .trailer-quelle {
            position: absolute;
            right: 5px;
            bottom: 5px;
            font-size: 12px;
            color: white;
            line-height: 1;
          }
        }
      }
      .kinostart-top {
        margin-right: 200px;
        position: relative;
        text-align: center;
        text-transform: uppercase;
        color: #f8c31f;
        font-weight: 700;
        font-family: 'Lato';
        margin-top: 30px;
        line-height: 0.9;
        &.lg {
          margin-top: 10px;
        }
        span {
          &.lg {
            $min_width: 1024px;
            $max_width: 2560px;
            $min_font: 15px;
            $max_font: 30px;
            @include fluid-type($min_width, $max_width, $min_font, $max_font);
          }
          &.xl,
          &.xxl,
          &.xxxl,
          &.xxxxl {
            $min_width: 1024px;
            $max_width: 2560px;
            $min_font: 15px;
            $max_font: 25px;
            @include fluid-type($min_width, $max_width, $min_font, $max_font);
          }
        }
        .text {
          &.lg {
            $min_width: 1024px;
            $max_width: 2560px;
            $min_font: 24px;
            $max_font: 30px;
            @include fluid-type($min_width, $max_width, $min_font, $max_font);
          }
          &.xl,
          &.xxl,
          &.xxxl,
          &.xxxxl {
            $min_width: 1024px;
            $max_width: 2560px;
            $min_font: 15px;
            $max_font: 55px;
            @include fluid-type($min_width, $max_width, $min_font, $max_font);
          }
        }
      }
    }

    .inhalt {
      z-index: 11;
      $self: &;
      background-position: top;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url('~@/assets/images/inhalt_bg_big.jpg');
      height: 648px;
      width: 100%;
      position: relative;
      font-family: 'Lato';
      .cast-eich {
        position: absolute;
        top: -37%;
        right: 50%;
        margin-right: -36%;
        width: 20%;
        z-index: 10;
        img {
          width: 100%;
        }
        &.xl {
          top: -30%;
          margin-right: -37%;
        }
        &.lg {
          top: -20%;
          margin-right: -45%;
        }
        &.xxxl {
          margin-right: -38%;
        }
        &.xxxxl {
          margin-right: -38%;
        }
      }
      &__wrapper {
        overflow: hidden;
        width: 100%;
        position: relative;
        text-align: center;
        font-family: 'Lato';

        &.lg,
        &.xl {
          background-image: none;
        }
        .inhalt-texte {
          margin: 0 auto;
          display: inline;
          .inhalt-cites {
            padding-top: 40px;
            width: 30%;
            position: relative;
            left: 50%;
            transform: translateX(-33%);
            img {
              width: 100%;
            }
            &.xxxl {
              width: 35%;
            }
          }
          .text-1 {
            position: relative;
            left: 50%;
            transform: translateX(-20%);
            text-align: left;
            color: white;
            margin-top: 20px;
            font-size: 19px;
            line-height: 1.4;
            width: 26%;
            font-weight: normal;

            &.xxxl {
              width: 30%;
            }

            &.lg,
            &.xl,
            &.xxl,
            &.xxxl,
            &.xxxxl {
              $min_width: 1024px;
              $max_width: 2560px;
              $min_font: 16px;
              $max_font: 19px;
              @include fluid-type($min_width, $max_width, $min_font, $max_font);
            }
            span {
              font-weight: 700;
            }
          }
        }
      }
    }
    @media (min-width: 1024px) and (max-width: 2342px) {
      .inhalt {
        background-image: url('~@/assets/images/inhalt_bg.png');
        .inhalt-texte {
          margin: 0 auto;
          display: inline;
          .inhalt-cites {
            padding-top: 40px;
            width: 40%;
            position: relative;
            left: 50%;
            transform: translateX(-33%);
            img {
              width: 100%;
            }
            &.xl {
              width: 40%;
            }
            &.lg {
              width: 50%;
            }
          }
          .text-1 {
            position: relative;
            left: 50%;
            transform: translateX(-20%);
            text-align: left;
            color: white;
            margin-top: 20px;
            font-size: 19px;
            line-height: 1.4;
            font-weight: normal;
            &.xxl {
              width: 35%;
            }
            &.xl {
              width: 40%;
            }
            &.lg {
              width: 50%;
            }
          }
        }
      }
    }
  }
  @media (max-height: 730px) and (max-width: 1274px) {
    .inhalt-overview {
      margin-top: -350px;
      .grass {
        .tt {
          margin-top: 170px;
        }
      }
    }
  }
</style>
