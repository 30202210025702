<!-- @format -->
<template>
  <div class="desktop-crew" id="desktop-crew" :class="$mq">
    <div class="crew__wrapper">
      <div class="crew-inhalt">
        <!--<img src="@/assets/images/crew_bg.jpg" />-->
        <div class="crew-text" :class="$mq">
          <div class="castcrew" :class="$mq">
            <img src="@/assets/images/castcrew.png" />
          </div>
          <div class="content">
            <div class="cast">
              <div class="person-cast" :class="$mq">
                <span>MARIJA</span>
                Emilia Schüle
              </div>
              <div class="person-cast" :class="$mq">
                <span>CURT</span>
                Günther Maria Halmer
              </div>
              <div class="person-cast" :class="$mq">
                <span>PHILIPP</span>
                Fabian Hinrichs
              </div>
              <div class="person-cast" :class="$mq">
                <span>ALMUT</span>
                Anna Stieblich
              </div>
              <div class="person-cast" :class="$mq">
                <span>ARINA</span>
                Masha Tokareva
              </div>
              <div class="person-cast" :class="$mq">
                <span>TINJA</span>
                Yanina Lisovskaya
              </div>
              <div class="person-cast" :class="$mq">
                <span>ARTJOM</span>
                Daniel Breder
              </div>
              <div class="person-cast" :class="$mq">
                <span>HEIDRUN</span>
                Hede Beck
              </div>
              <div class="person-cast" :class="$mq">
                <span>HERR SIEVERT</span>
                Fritz Roth
              </div>
              <div class="person-cast" :class="$mq">
                <span>SANITÄTER</span>
                Ricky Watson
              </div>
              <div class="person-cast" :class="$mq">
                <span>SVENJA</span>
                Sina Bianca Hentschel
              </div>
            </div>
            <div class="crew">
              <div class="person" :class="$mq">
                <span>Drehbuch und Regie</span>
                Nadine Heinze und Marc Dietschreit
              </div>
              <div class="person" :class="$mq">
                <span>Produzenten</span>
                Marc Müller-Kaldenberg, Pascal Nothdurft
              </div>
              <div class="person" :class="$mq">
                <span>Koproduzentin</span>
                Tanja Ziegler
              </div>
              <div class="person" :class="$mq">
                <span>Produktion</span>
                Zieglerfilm Baden-Baden
              </div>
              <div class="person" :class="$mq">
                <span>in Koproduktion mit</span>
                Ziegler Film, SWR (Redaktion: Stefanie<br />
                Groß), ARTE (Redaktion: Barbara Häbe)
              </div>
              <div class="person" :class="$mq">
                <span>Produktionsleitung</span>
                Robert Geisler
              </div>
              <div class="person" :class="$mq">
                <span>Bildgestaltung</span>
                Holly Fink
              </div>
              <div class="person" :class="$mq">
                <span>Schnitt</span>
                Andrea Mertens
              </div>
              <div class="person" :class="$mq">
                <span>Szenenbild</span>
                Caro Ohmert
              </div>
              <div class="person" :class="$mq">
                <span>Kostüm</span>
                Monika Gebauer
              </div>
              <div class="person" :class="$mq">
                <span>Maske</span>
                Nicolay Lißner, Anna Kunz
              </div>
              <div class="person" :class="$mq">
                <span>Originalton </span>
                Rüdiger Fleck
              </div>
              <div class="person" :class="$mq">
                <span>Casting</span>
                Emrah Ertem
              </div>
              <div class="person" :class="$mq">
                <span>Musik</span>
                Daniel Sus, Can Erdogan
              </div>
              <div class="person" :class="$mq">
                <span>Gefördert durch</span>
                MFG Medien- und Filmgesellschaft<br />
                Baden-Württemberg, DFFF Deutscher Filmförderfonds,<br />
                FFA Filmförderungsanstalt
              </div>
              <div class="person" :class="$mq">
                <span>Kofinanziert von</span>
                Musikverlag EDITION TANJA
              </div>
              <div class="person" :class="$mq">
                <span>Verleih Deutschland</span>
                Filmwelt Verleihagentur
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {}
</script>

<style lang="scss">
  .desktop-crew {
    padding-top: 50px;
    z-index: 2;
    $self: &;
    background-color: white;
    overflow: hidden;
    height: 656px;
    width: 100%;
    position: relative;
    &.lg {
      height: 644px;
    }
    &.xl {
      height: 644px;
    }
    .crew-text {
      margin-top: 31px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      font-family: 'Lato';
      text-align: center;
      &.xl {
        margin-top: 21px;
      }
      &.lg {
        margin-top: 21px;
      }
      .castcrew {
        z-index: 100;
      }
      .content {
        margin-top: 22px;
        width: 100%;
        display: flex;
        flex-direction: row;
        .cast {
          margin-right: 15px;
          width: 50%;
          text-align: right;
          .person-cast {
            $min_width: 1024px;
            $max_width: 2560px;
            $min_font: 16px;
            $max_font: 19px;
            @include fluid-type($min_width, $max_width, $min_font, $max_font);
            color: black;
            span {
              font-weight: 700;
              font-size: 19px;
              color: #259aa4;
            }
          }
        }
        .crew {
          text-align: left;
          margin-left: 15px;
          width: 50%;
          .person {
            $min_width: 1024px;
            $max_width: 2560px;
            $min_font: 16px;
            $max_font: 19px;
            @include fluid-type($min_width, $max_width, $min_font, $max_font);
            color: black;
            span {
              font-weight: 700;
              font-size: 19px;
              color: #259aa4;
            }
          }
        }
      }
    }
  }
</style>
