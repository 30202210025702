var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('mq-layout',{attrs:{"mq":"lg+"}},[_c('div',{staticClass:"desktop-index",attrs:{"id":"desktop-index"}},[_c('div',{staticClass:"wrapper",class:[_vm.isFixed ? 'isAbsolute' : ' isFixed']},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"bg",style:([
            _vm.ratio < 2
              ? {
                  backgroundSize: 'cover',
                }
              : {
                  backgroundSize: '100% auto',
                } ])}),_c('div',{staticClass:"filmfest",class:_vm.$mq},[_c('img',{attrs:{"src":require("@/assets/images/filmpreis.png")}})]),_c('div',{staticClass:"zitat",class:_vm.$mq},[_c('img',{attrs:{"src":require("@/assets/images/cites.png")}})])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }