<!-- @format -->

<template>
  <footer class="footer" :class="$mq">
    <div class="footer__content">
      <div class="bild-crew" :class="$mq">
        <img src="@/assets/images/crew_image.jpg" />
      </div>
      <div class="footer__tt" :class="$mq">
        <img :src="require(`@/assets/images/` + $config.tt_small)" alt="" />
      </div>
      <div class="footer__kinostart-footer" :class="$mq">
        Ab 13.01.2022 auf DVD <br /><span :class="$mq"
          >Ab 23.12.2021 als digitaler Download</span
        >
      </div>
      <div class="footer__von" :class="$mq">
        EIN FILM VON NADINE HEINZE UND MARC DIETSCHREIT
      </div>
      <!-- SECTION Footer Company -> Chooses Footer by $config.verantwortlich -> look @ computed -->
      <component
        class="footer__company"
        :is="companyFooter"
        :teilnahmebedingung="teilnahmebedingung"
      ></component>
    </div>
    <div class="footer__billing" :class="$mq">
      <img src="@/assets/images/billing.png" alt="" />
    </div>
    <div class="cb-restore"></div>
  </footer>
</template>

<script>
  export default {
    props: {
      billing: {
        type: Boolean,
        default: true,
      },
      youtubenote: {
        type: Boolean,
        default: true,
      },
      kinostart: {
        type: String,
        default: 'Releaseline',
      },
      teilnahmebedingung: {
        type: String,
        default: 'leer',
      },
    },
    computed: {
      companyFooter() {
        return () =>
          import(
            `@/components/Base/Elements/Companies/${this.$config.verantwortlich.toLowerCase()}/Footer.vue`
          )
      },
      companySocial() {
        return () =>
          import(
            `@/components/Base/Elements/Companies/${this.$config.verantwortlich.toLowerCase()}/Social.vue`
          )
      },
    },
  }
</script>

<style lang="scss">
  .footer {
    $self: &;
    z-index: 5;
    position: relative;
    text-align: center;
    height: 647px;
    font-size: 0.875rem;
    width: 100%;
    padding-bottom: 5rem;
    background-color: #259aa4;
    &.sm,
    &.md {
      padding-bottom: 0;
      background-color: #259aa4;
    }
    &.lg {
      height: 527px;
    }
    &.xl {
      height: 527px;
    }

    &__content {
      position: relative;
      margin: 0 auto;
      padding-top: 2rem;
      .bild-crew {
        position: absolute;
        top: -257px;
        z-index: 100;
        left: 50%;
        margin-left: -440px;
        &.sm,
        &.md {
          display: none;
        }
      }
      #{$self}__tt {
        margin: 40px auto 20px auto;
        &.sm,
        &.md {
          padding: 0 5%;
          margin: 10px auto 0 auto;
        }
      }
      #{$self}__kinostart-footer {
        line-height: 0.9;
        color: #f8c31f;
        text-transform: uppercase;
        padding: 10px 0 10px 0;
        font-family: 'Lato';
        span {
          margin-top: 0;
          &.lg,
          &.xl,
          &.xxl,
          &.xxxl,
          &.xxxxl {
            $min_width: 1024px;
            $max_width: 2560px;
            $min_font: 25px;
            $max_font: 25px;
            @include fluid-type($min_width, $max_width, $min_font, $max_font);
            font-weight: bold;
          }
          &.sm,
          &.md {
            $min_width: 300px;
            $max_width: 1023px;
            $min_font: 15px;
            $max_font: 30px;
            @include fluid-type($min_width, $max_width, $min_font, $max_font);
          }
        }
        &.lg,
        &.xl,
        &.xxl,
        &.xxxl,
        &.xxxxl {
          $min_width: 1024px;
          $max_width: 2560px;
          $min_font: 35px;
          $max_font: 45px;
          @include fluid-type($min_width, $max_width, $min_font, $max_font);
          font-weight: bold;
        }
        &.sm,
        &.md {
          background-color: #259aa4;
          padding-top: 15px;
          $min_width: 300px;
          $max_width: 1023px;
          $min_font: 22px;
          $max_font: 45px;
          @include fluid-type($min_width, $max_width, $min_font, $max_font);
        }
      }
      #{$self}__von {
        margin-bottom: 25px;
        color: #004f56;
        font-weight: 700;
        $min_width: 1024px;
        $max_width: 2560px;
        $min_font: 18px;
        $max_font: 28px;
        @include fluid-type($min_width, $max_width, $min_font, $max_font);
        &.sm,
        &.md {
          margin-bottom: 10px;
        }
      }
    }
    #{$self}__billing {
      margin-top: 10px;
      &.sm,
      &.md {
        margin-top: 10px;
      }
      &.sm,
      &.md,
      &.lg,
      &.xl {
        padding: 0 5%;
      }
    }
  }
</style>
