<!-- @format -->

<template>
  <flickity ref="flickity" :options="flickityOptions">
    <div v-for="cite in $config.cites" :key="cite.text" class="carousel-cell">
      <cite class="base-cite">
        <p class="base-cite__text" v-text="cite.text" />
        <p class="base-cite__author" v-text="cite.author" />
      </cite>
    </div>
  </flickity>
</template>

<script>
  import Flickity from 'vue-flickity'
  require('flickity-fade')

  export default {
    components: {
      Flickity,
    },
    props: {
      kinostart: {
        type: String,
        default: 'Releaseline',
      },
    },
    data() {
      return {
        flickityOptions: {
          initialIndex: 1,
          prevNextButtons: false,
          pageDots: false,
          autoPlay: true,
          fade: true,
          draggable: false,
        },
      }
    },
  }
</script>

<style lang="scss" scoped>
  .carousel-cell {
    width: 660px !important;
  }
  .base-cite {
    $self: &;
    width: 660px !important;
    &__text {
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 900;
      color: white;
      font-size: 2.5rem;
      line-height: 2.8rem;
      margin: 0 auto;
      margin-bottom: 15px;
      text-align: center;
      text-transform: uppercase;
      width: 100% !important;
    }
    &__author {
      text-transform: uppercase;
      font-style: normal;
      text-align: center;
    }
  }
</style>
