<template>
  <!-- PRESS-MODAL -->
  <div v-if="pressmodal2" class="presse2">
    <div class="presse__wrapper" :class="$mq">
      <div class="presse__right hidden lg:block">
        <img src="@/assets/images/presse.jpg" alt />
      </div>
      <div class="presse__left">
        <h1>Betreuende Presseagentur:</h1>
        <address>
          <div class="presse__name">
            {{ $config.press.agencies[$config.press.agency].name }}
          </div>
          <div class="presse__person">
            {{ $config.press.agencies[$config.press.agency].person }}
          </div>
          <div class="presse__adresse">
            {{ $config.press.agencies[$config.press.agency].street }}
            <br />
            {{ $config.press.agencies[$config.press.agency].city }}
            <br />
            {{ $config.press.agencies[$config.press.agency].phone }}
            <br />
            <a
              :href="
                'mailto:' + $config.press.agencies[$config.press.agency].email
              "
              >{{ $config.press.agencies[$config.press.agency].email }}</a
            >
          </div>
        </address>
        <p class="presse__server">
          <a href="//www.filmpresskit.de/" target="_blank"
            >Zum Presseserver >></a
          >
        </p>
        <div class="presse__close" @click="close">&times;</div>
      </div>
    </div>
  </div>
</template>

<script>
  import { EventBus } from '@/plugins/event-bus.js'
  export default {
    data() {
      return {
        pressmodal2: false,
      }
    },
    created() {
      /* eslint-disable-next-line no-undef */
      EventBus.$on(
        'pressmodal2',
        function () {
          this.pressmodal2 = true
        }.bind(this)
      )
    },
    methods: {
      close() {
        this.pressmodal2 = false
      },
    },
  }
</script>

<style lang="scss">
  /** @format */

  .presse2 {
    $self: &;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 222;
    font-size: 14px;
    &__wrapper2 {
      display: flex;
      flex-direction: row;
      position: relative;
      border: 2px solid black;
      width: 33%;
      &.sm {
        flex-direction: column;
        #{$self}__right {
          display: none;
        }
      }
      &.sm,
      &.md {
        width: 100%;
        #{$self}__right {
          min-width: auto;
          background-color: BLACK;
        }
        #{$self}__left {
          padding: 2rem 0 2rem 2rem;
        }
      }
      &.lg {
        width: 50%;
      }
      #{$self}__right {
        min-width: 283px;
      }
      #{$self}__left {
        text-transform: lowercase;
        position: relative;
        background-color: rgba(0, 0, 0, 0.4);
        color: white;
        padding: 2rem;
        flex: 1;
        text-align: left;
        h1 {
          font-weight: 700;
          margin-bottom: 2rem;
          font-size: 24px !important;
          text-transform: capitalize !important;
          // font-bold mb-4 text-2xl lg:text-3xl leading-none
        }
        address {
          font-style: normal;
          text-transform: capitalize;
          #{$self}__name {
            font-weight: 700;
            font-size: 20px;
          }
        }
        a {
          color: #fff;
          font-size: 14px !important;
          &:hover {
            color: $secondary;
          }
        }
        #{$self}__close {
          position: absolute;
          top: 0;
          right: 0;
          color: #fff;
          margin-right: 10px;
          margin-top: 16px;
          cursor: pointer;
          font-size: 48px;
          line-height: 0;
          font-weight: 700;
          &:hover {
            color: red;
          }
          // absolute top-0 right-0 hover:text-red-500 text-black mr-4 mt-2 cursor-pointer text-2xl font-bold
        }
        // relative bg-white flex-1 p-4 lg:p-10 text-left
      }
      // flex flex-col lg:flex-row relative border-secondary border-4 w-full lg:w-1/3
    }
  }
  /** absolute lg:fixed top-0 left-0 w-full bg-transparent lg:bg-modal z-modal lg:h-screen flex justify-center items-center shadow-lg */
</style>
