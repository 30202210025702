<!-- @format -->

<template>
  <mq-layout mq="lg+">
    <header id="header" class="header" :class="$mq">
      <div class="header__content">
        <nav id="navi">
          <BaseNavigation />
        </nav>
        <div class="right">
          <nav v-if="kinofinder" id="finder">
            <BaseKinofinder v-if="$config.kinoservice.display" />
          </nav>
          <div :class="$mq" class="line">
            <div class="gray">&nbsp;</div>
          </div>
          <div class="bg_color">
            <!--<div>&nbsp;</div>-->
            <div class="bild1" v-if="isDefaultImage">
              <img src="@/assets/images/false.png" />
            </div>
            <div class="bild2" v-else>
              <img src="@/assets/images/bg_topright.jpg" />
            </div>
          </div>
          <component
            :is="companySocial"
            :instagram="true"
            :facebook="true"
            :youtube="false"
            v-if="social"
            class="header__social"
          ></component>
        </div>
      </div>
    </header>
  </mq-layout>
</template>

<script>
  /** @format */

  export default {
    props: {
      social: {
        type: Boolean,
        default: true,
      },
      kinofinder: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        isDefaultImage: true,
      }
    },
    created() {
      window.addEventListener('scroll', this.handleScroll)
    },
    destroyed() {
      window.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
      handleScroll(event) {
        // Any code to be executed when the window is scrolled
        console.log(window.scrollY)
        if (window.scrollY > 100) {
          return (this.isDefaultImage = false)
        }
        if (window.scrollY <= 100) {
          if (!this.defaultImage) {
            return (this.isDefaultImage = true)
          }
        }
      },
    },
    computed: {
      companySocial() {
        return () =>
          import(
            `@/components/Base/Elements/Companies/${this.$config.verantwortlich.toLowerCase()}/Social.vue`
          )
      },
    },
  }
</script>

<style lang="scss">
  /** @format */

  .header {
    $self: &;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: $header-height;
    background-color: transparent;
    z-index: 9999;
    overflow: hidden;
    &__content {
      position: relative;
      display: flex;
      justify-content: center;
      flex-direction: row;
      padding: $header-padding;
      height: $header-height;
      .right {
        .bg_color {
          position: absolute;
          right: 0;
          top: 0;
          display: block;
          transition: top 0.3s;
        }
      }
    }
    &__social {
      position: absolute;
      top: 0px;
      right: 15px;
      text-align: right;
    }
  }
</style>
