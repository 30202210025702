<!-- @format -->
<template>
  <div class="top-header">
    <div class="top-header__wrapper">
      <div class="top-header__trigger-wrapper" @click="openMenu">
        <a id="nTrigger" class="top-header__trigger"> <i></i><i></i><i></i> </a>
      </div>
      <component class="social" :is="companySocial" v-if="social"></component>
    </div>
    <div id="top_nav" class="offcanvas-bar" :class="{ 'show-offcanvas': open }">
      <div
        class="offcanvas-bar__wrapper"
        :class="$mq"
        v-if="$router.currentRoute.path === '/'"
      >
        <div
          v-for="(value, key) in this.$config.navigation"
          :key="value.sitename"
        >
          <!-- eslint-disable-next-line vue/require-v-for-key -->
          <a
            class="nav-item"
            :class="$mq"
            v-scroll-to="{ el: '.desktop-' + value.sitename, offset: -55 }"
            @click="closeMenu()"
          >
            {{ key }}
          </a>
          <div
            v-if="key === 'Kinopremiere'"
            class="nav-item"
            @click="openPremiere"
          >
            {{ key }}
          </div>
        </div>

        <div class="nav-share-top">
          <div class="offcanvas-bar__kinofinder" :class="$mq">
            <div
              :href="$config.kinoservice.finder + $config.kinoservice.slug"
              target="_blank"
              class="nav-item hovering"
              :class="$mq"
              @click="open_de_kinofinder"
            >
              <img src="@/assets/images/kinofinder.png" />
              <div class="background"></div>
            </div>
          </div>
          <div class="offcanvas-bar__filmwecker" :class="$mq">
            <div
              target="_blank"
              class="nav-item hovering"
              @click="open_de_filmwecker"
              :class="$mq"
            >
              <img src="@/assets/images/filmwecker.png" />
              <div class="background"></div>
            </div>
          </div>
        </div>
        <div class="bottom-nav">
          <div class="filmwelt-nav" :class="$mq">
            <a
              class="external"
              href="http://www.filmweltverleih.de/"
              target="_blank"
              >FILMWELT HOME</a
            >
            <a @click.prevent="emitPressClick2" href="#">Presse</a>
            <a
              class="external"
              href="http://www.filmweltverleih.de/page/impressum"
              target="_blank"
              >Impressum</a
            >
            <a
              class="external"
              href="http://www.filmweltverleih.de/page/datenschutz"
              target="_blank"
              >Datenschutz</a
            >
          </div>
          <PressModal2 />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { EventBus } from '@/plugins/event-bus.js'
  import PressModal2 from '@/components/Base/Elements/Modals/Press2.vue'

  export default {
    components: {
      PressModal2,
    },
    props: {
      social: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        open: false,
      }
    },
    computed: {
      companySocial() {
        return () =>
          import(
            `@/components/Base/Elements/Companies/${this.$config.verantwortlich.toLowerCase()}/Social.vue`
          )
      },
    },
    methods: {
      open_de_kinofinder() {
        window.open(
          `https://www.kino.de/film/${this.$config.kinoservice.slug}/`
        )
      },
      open_de_filmwecker() {
        window.open(
          `https://www.kino-zeit.de/alert/${this.$config.kinoservice.id}`
        )
      },
      openPremiere() {
        this.$router.push('/preview')
        this.open = !this.open
        var element = document.getElementById('nTrigger')
        element.classList.toggle('active')
      },
      openMenu() {
        this.open = !this.open
        EventBus.$emit('close-expandables')
        var element = document.getElementById('nTrigger')
        element.classList.toggle('active')
      },
      closeMenu(link) {
        this.open = !this.open
        var element = document.getElementById('nTrigger')
        element.classList.toggle('active')
        if (this.$route.meta.showModal) {
          this.$router.go(-1)
        }
        if (this.$router.currentRoute.path === '/artists') {
          this.$router.replace('/').then(function () {
            window.location.href = `#desktop-${link}`
          })
        }
      },
      emitPressClick2() {
        EventBus.$emit('pressmodal2')
      },
    },
  }
</script>

<style lang="scss">
  .top-header {
    $self: &;
    position: relative;

    &__wrapper {
      position: relative;
      width: 51px;
      height: 51px;
      position: fixed;
      left: 0;
      top: 0;
      color: black;
      z-index: 9999999999;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background-color: #f8c31f;
      .social {
        margin-right: 10px;
        margin-bottom: 12px;
        div:nth-child(1) {
          height: 51px !important;
        }
        a {
          display: inline-block;
          margin-top: -3px;
          font-size: 80px;
        }
        svg path {
          fill: #ffffff;
        }
      }
      #{$self}__trigger-wrapper {
        display: flex;
        flex-direction: row;
        font-size: 80px;
        margin-top: 5px;
        font-weight: 900;
        #{$self}__trigger {
          margin-top: 11px;
          cursor: pointer;
          width: 30px;
          height: 50px;
          padding: 0 10px;
          animation: outAll 0.8s forwards;

          i {
            background-color: #ffffff;
            border-radius: 2px;
            content: '';
            display: block;
            width: 100%;
            height: 4px;
          }
          i:nth-child(1) {
            -webkit-animation: outT 0.8s backwards;
            animation: outT 0.8s backwards;
            -webkit-animation-direction: reverse;
            animation-direction: reverse;
          }
          i:nth-child(2) {
            margin: 5px 0;
            -webkit-animation: outM 0.8s backwards;
            animation: outM 0.8s backwards;
            -webkit-animation-direction: reverse;
            animation-direction: reverse;
          }
          i:nth-child(3) {
            -webkit-animation: outBtm 0.8s backwards;
            animation: outBtm 0.8s backwards;
            -webkit-animation-direction: reverse;
            animation-direction: reverse;
          }
          &.active {
            animation: inAll 0.8s forwards;
            i:nth-child(1) {
              -webkit-animation: inT 0.8s forwards;
              animation: inT 0.8s forwards;
            }
            i:nth-child(2) {
              -webkit-animation: inM 0.8s forwards;
              animation: inM 0.8s forwards;
            }
            i:nth-child(3) {
              -webkit-animation: inBtm 0.8s forwards;
              animation: inBtm 0.8s forwards;
            }
          }
        }
      }
    }
  }
  .show-offcanvas {
    opacity: 1 !important;
    -webkit-transform: translateX(0) !important;
    transform: translateX(0) !important;
  }
  .offcanvas-bar {
    $self: &;
    opacity: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #259aa4;
    width: 100%;
    transform: translateY(-100%);
    -webkit-transition: -webkit-transform 0.6s ease-out;
    transition: transform 0.6s ease-out;
    z-index: 200;
    &__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      $min_width: 1024px;
      $max_width: 2560px;
      $min_font: 40px;
      $max_font: 80px;
      @include fluid-type($min_width, $max_width, $min_font, $max_font);

      &.sm {
        font-size: 1.5rem;
      }
      .nav-item {
        position: relative;
        cursor: pointer;
        font-weight: 400;
        margin: 10px 0;
        color: white;
        &.sm {
          margin: 5px 0;
        }
        &:hover {
          color: #f8c31f;
          .background {
            height: 56px;
            transition: height 0.25s ease-in;
          }
        }
        .background {
          height: 0px;
          transition: height 0.15s ease-out;
          background-color: #f8c31f;
          width: 100%;
          margin-top: -45px;
          margin-left: 10px;
        }
      }
      .icon-link {
        margin: 0 auto;
        text-align: center;
        margin-top: 10px;
        display: block;
        width: 70px;
        background-color: $facebook;
        padding: 5px 15px;
        color: white;
        img {
          vertical-align: middle;
        }
      }
      .nav-share-top {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 10px;
        #{$self}__kinofinder {
          position: relative;
          text-align: left;
          margin-right: 10px;
          &.lg {
            width: 40%;
            img {
              width: 100%;
            }
          }
        }
        #{$self}__filmwecker {
          position: relative;
          text-align: left;
          margin-left: 10px;
          &.lg {
            width: 40%;
            img {
              width: 100%;
            }
          }
        }
      }

      .bottom-nav {
        .filmwelt-nav {
          $self: &;
          display: flex;
          height: 50px;
          flex-direction: row;
          font-weight: 700;
          margin-top: 70px;
          justify-content: center;
          text-transform: uppercase;
          $min_width: 1024px;
          $max_width: 2560px;
          $min_font: 14px;
          $max_font: 19px;
          @include fluid-type($min_width, $max_width, $min_font, $max_font);
          a {
            color: white;
            &:hover {
              color: #f8c31f;
            }
            &:not(:last-child):after {
              color: white;
              content: '|';
              padding: 0 5px 0 5px;
            }
          }
          &.sm {
            background-color: white;
            a {
              &:after {
                content: '';
              }
              &:last-child {
                margin-left: 0;
              }
            }
          }
          &.md,
          &.lg,
          &.xl,
          &.xxl {
            flex-direction: row;
          }
        }
      }
    }
  }
  @media only screen and (min-width: 600px) {
    .navTrigger {
      transform: translateX(50%) translateY(50%) scale(2);
    }
  }

  @keyframes inM {
    50% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(45deg);
      background-color: white;
    }
  }

  @keyframes outM {
    50% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(45deg);
    }
  }

  @keyframes inT {
    0% {
      transform: translateY(0px) rotate(0deg);
      background-color: white;
    }
    50% {
      transform: translateY(9px) rotate(0deg);
    }
    100% {
      transform: translateY(9px) rotate(135deg);
      background-color: white;
    }
  }

  @keyframes outT {
    0% {
      transform: translateY(0px) rotate(0deg);
    }
    50% {
      transform: translateY(9px) rotate(0deg);
    }
    100% {
      transform: translateY(9px) rotate(135deg);
    }
  }

  @keyframes inBtm {
    0% {
      transform: translateY(0px) rotate(0deg);
      background-color: white;
    }
    50% {
      transform: translateY(-9px) rotate(0deg);
    }
    100% {
      transform: translateY(-9px) rotate(135deg);
      background-color: white;
    }
  }

  @keyframes outBtm {
    0% {
      transform: translateY(0px) rotate(0deg);
    }
    50% {
      transform: translateY(-9px) rotate(0deg);
    }
    100% {
      transform: translateY(-9px) rotate(135deg);
    }
  }
</style>
