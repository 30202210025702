<template>
  <mq-layout mq="lg+">
    <div class="desktop-index" id="desktop-index">
      <!-- NOTE Setzt Top auf fixed, wenn als Property in der App.vue "fixed" übergeben wird, sonst "absolute" z.B. position="fixed" -->
      <div :class="[isFixed ? 'isAbsolute' : ' isFixed']" class="wrapper">
        <div class="content">
          <div
            :style="[
              ratio < 2
                ? {
                    backgroundSize: 'cover',
                  }
                : {
                    backgroundSize: '100% auto',
                  },
            ]"
            class="bg"
          ></div>
          <!--          <div @click="kinoliste" class="stoerer" :class="$mq">
            <img src="@/assets/images/top/stoerer.png" alt />
          </div>-->
          <div class="filmfest" :class="$mq">
            <img src="@/assets/images/filmpreis.png" />
          </div>
          <div class="zitat" :class="$mq">
            <img src="@/assets/images/cites.png" />
          </div>
        </div>
      </div>
    </div>
  </mq-layout>
</template>

<script>
  /** @format */

  import { EventBus } from '@/plugins/event-bus.js'

  export default {
    props: {
      kinostart: {
        type: String,
        default: 'Releaseline',
      },
      ratio: {
        type: Number,
        default: 1.78,
      },
      trailer: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      isFixed() {
        return this.position === 'fixed'
      },
    },
    methods: {
      ytVideo() {
        EventBus.$emit('open-yt')
      },
      kinoliste() {
        EventBus.$emit('open-kinoliste')
      },
    },
  }
</script>

<style lang="scss">
  /** @format */

  .isFixed {
    position: fixed;
  }
  .isAbsolute {
    position: absolute;
  }
  .desktop-index {
    z-index: 0;
    position: relative;
    overflow-y: hidden;
    height: 100vh;
    .wrapper {
      background-color: #259aa4;
      top: 0;
      left: 0;
      overflow: hidden;
      height: 100vh;
      width: 100%;
      .content {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100vh;

        .bg {
          width: 100%;
          height: 100%;
          background: #259aa4 url($assets + 'images/top_bg.jpg') center top
            no-repeat;
          overflow: hidden;
        }
        .filmfest {
          position: absolute;
          z-index: 1;
          top: 37%;
          right: 5%;
          width: 12%;
          &.lg {
            width: 15%;
            right: 2%;
          }
          img {
            width: 100%;
          }
        }
        .zitat {
          position: absolute;
          z-index: 1;
          bottom: 34%;
          left: 3%;
          width: 14%;
          &.lg {
            bottom: 40%;
            width: 20%;
          }
          &.xl {
            bottom: 40%;
          }
          &.xxxxl {
            bottom: 41%;
          }
          img {
            width: 100%;
          }
        }
      }
    }
  }
</style>
