<!-- @format -->

<template>
  <footer
    class="
      hidden
      lg:block
      fixed
      left-0
      bottom-0
      w-full
      footer-bg footer-height
      font-opensans
      z-max
    "
  >
    <div class="flex flex-row justify-between">
      <div class="flex flex-row">
        <div class="mt-2 ml-4">
          <social />
        </div>
        <div class="flex flex-col pl-2 mt-2">
          <div
            class="
              flex flex-col
              md:flex-row
              sm:justify-center
              mb-2
              font-bold
              text-xxs
              xl:text-sm
              navpoints
              mb-4
              sm:mb-0
            "
          >
            <a
              :href="$config.kinoservice.finder + $config.kinoservice.slug"
              target="_blank"
              >KINOFINDER</a
            >
            <a
              :href="$config.kinoservice.alert + $config.kinoservice.id"
              target="_blank"
              >FILMWECKER</a
            >
            <a href="https://www.prokino.de/impressum.html" target="_blank"
              >IMPRESSUM</a
            >
            <a
              href="https://www.prokino.de/movies/datenschutzbestimmungen"
              target="_blank"
              >DATENSCHUTZ</a
            >
            <a href="https://prokino.medianetworx.de/" target="_blank"
              >PRESSESERVER</a
            >
          </div>
          <div
            v-if="company == 'Prokino'"
            class="relative text-xxs text-gray-900 font-normal"
          >
            &copy; 2019 Prokino. Alle Rechte vorbehalten. made by
            <a
              class="text-gray-900 hover:text-bluenormal"
              href="http://www.novagraphix.de"
              target="_blank"
              >novagraphix</a
            >
          </div>
        </div>
        <div class="footer-height flex items-center ml-4">
          <div class="cb-restore"></div>
        </div>
      </div>
      <div class="leading-none font-lora text-primary font-normal">
        <BaseKinostart
          class="mr-4 uppercase font-extrabold text-black"
          :kinostart="kinostart"
        />
      </div>
    </div>
  </footer>
</template>

<script>
  import Social from '@/components/Base/Elements/Companies/prokino/Social.vue'

  export default {
    components: {
      Social,
    },
    props: {
      social: {
        type: Boolean,
        default: true,
      },
      billing: {
        type: Boolean,
        default: true,
      },
      kinostart: {
        type: String,
        default: 'Releaseline',
      },
      company: {
        type: String,
        default: 'Prokino',
      },
    },
  }
</script>

<style lang="scss"></style>
