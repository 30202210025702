<template>
  <div id="desktop-fotos" ref="fotos" class="desktop-fotos" :class="$mq">
    <BaseCarousel
      :autoplay="false"
      :preview="true"
      :fade="true"
      :fullscreen="true"
      :dots="true"
    >
      <div class="source">
        <div
          v-for="value in this.$config.gallery.count"
          :key="value"
          class="carousel-cell"
        >
          <div class="fotos-caro" :class="$mq">
            <img
              :src="require(`@/assets/images/gallery/` + value + `.png`)"
              alt
              class="pictures"
            />
          </div>
          <div class="fotos-caro1" :class="$mq">
            <img
              :src="require(`@/assets/images/gallery/` + value + `.jpg`)"
              alt
              class="pictures"
            />
          </div>
          <div class="fotos-caro2" :class="$mq">
            <img
              :src="require(`@/assets/images/` + value + `.png`)"
              alt
              class="pictures"
            />
          </div>
        </div>
      </div>
    </BaseCarousel>
    <div class="eich_cast" :class="$mq">
      <img src="@/assets/images/eich_cast.png" />
    </div>
  </div>
</template>
<script>
  export default {
    /*    props: {
      gallery: {
        type: Boolean,
        default: false,
      },
    },*/
    data() {
      return {
        index: null,
        foto: null,
        images: [],
        windowWidth: window.innerWidth,
      }
    },
    methods: {
      openGallery(number) {
        this.index = number
      },
    },
    mounted() {
      for (let index = 0; index < this.$config.gallery.count; index++) {
        this.images[index] = require(`@/assets/images/gallery/` +
          this.pad(index + 1, 1) +
          `.jpg`)
      }
      window.onresize = () => {
        this.windowWidth = window.innerWidth
      }
    },
  }
</script>

<style lang="scss">
  .desktop-fotos {
    background-color: white;
    z-index: 3;
    position: relative;
    cursor: pointer;
    width: 100%;
    height: auto;
    padding-bottom: 100px;
    .eich_cast {
      z-index: 666;
      position: absolute;
      bottom: -60px;
      left: 50%;
      transform: translateX(-190px);
      width: 20%;
      img {
        width: 100%;
      }
      &.lg {
        transform: translateX(-120px);
        bottom: -20px;
        width: 21%;
        img {
          width: 100%;
        }
      }
      &.xl {
        transform: translateX(-130px);
        bottom: -40px;
      }
      &.xxxl {
        transform: translateX(-150px);
        bottom: -80px;
      }
      &.xxxxl {
        transform: translateX(-250px);
        bottom: -80px;
      }
    }
    .fotos-caro2 {
      z-index: 3;
      display: none;
      &.lg {
        display: block;
      }
    }
    .fotos-caro {
      z-index: 3;
      &.lg {
        display: none;
      }
      &.xxxxl,
      &.endless {
        display: none;
      }
    }
    .fotos-caro1 {
      display: none;
      &.xxxxl {
        display: block;
      }
    }
    .flickity-page-dots {
      margin-left: 280px;
    }
    .flickity-page-dots .dot.is-selected {
      background-color: #259aa4;
    }
    .flickity-prev-next-button.previous {
      left: 0;
    }
    .flickity-prev-next-button.next {
      right: 0;
    }
    .flickity-prev-next-button {
      width: 3%;
      height: 15%;
      border-radius: 0;
    }
    .flickity-prev-next-button .flickity-button-icon {
      position: absolute;
      left: 20%;
      top: 20%;
      width: 60%;
      height: 60%;
    }
    .flickity-button-icon:hover {
      fill: #f8c31f;
    }
    .flickity-prev-next-button,
    .flickity-prev-next-button:hover {
      background-color: #259aa4;
    }
    .column {
      float: left;
      width: 33.2%;
      overflow: hidden;
      img,
      video {
        cursor: pointer;
        -moz-transform: scale(1);
        -webkit-transform: scale(1);
        -o-transform: scale(1);
        -ms-transform: scale(1);
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: transform 1s ease-out;
        -moz-transition: transform 1s ease-out;
        -ms-transition: transform 1s ease-out;
        transition: transform 1s ease-out;

        &:hover {
          -moz-transform: scale(1.05);
          -webkit-transform: scale(1.05);
          -o-transform: scale(1.05);
          -ms-transform: scale(1.05);
          -webkit-transform: scale(1.05);
          transform: scale(1.05);
        }
      }
    }
    &.xxxxl {
      padding-bottom: 190px;
    }
    &.lg {
      padding-bottom: 50px;
    }
  }
</style>
