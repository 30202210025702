<!-- @format -->
<template>
  <div class="base-kinofinder" v-if="$config.kinoservice.display" :class="$mq">
    <ul :class="$mq">
      <li :class="$mq">
        <a
          :class="$mq"
          :href="$config.kinoservice.finder + $config.kinoservice.slug"
          target="_blank"
          >{{ $config.kinoservice.finder_text }}</a
        >
      </li>
      <li :class="$mq" v-if="$config.kinoservice.alert_text !== ''">
        <a
          :class="$mq"
          :href="$config.kinoservice.alert + $config.kinoservice.id"
          target="_blank"
          >{{ $config.kinoservice.alert_text }}</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        hover: false,
      }
    },
  }
</script>

<style lang="scss">
  .base-kinofinder {
    font-family: 'Lato';
    position: relative;
    height: $header-height;
    &.sm,
    &.md {
      height: auto;
    }
    ul {
      margin: 0;
      padding: 0;
      text-align: center;
      list-style-type: none;
      line-height: $header-lineheight;
      display: flex;
      &.sm,
      &.md {
        flex-direction: column;
      }

      .hovered {
        transform: translateY(-$header-height);
      }
      li {
        height: $header-height;
        display: inline-block;
        text-align: left;
        &.sm,
        &.md {
          height: auto;
        }
        a {
          text-decoration: none;
          text-transform: uppercase;
          height: $header-height;
          line-height: $header-height;
          color: $header-link;
          font-weight: 500;
          $min_width: 1024px;
          $max_width: 2560px;
          $min_font: 16px;
          $max_font: $header-fontsize;
          @include fluid-type($min_width, $max_width, $min_font, $max_font);
          &:hover {
            color: $header-link-hover;
          }
          &.sm,
          &.md {
            font-weight: 600;
            margin-bottom: 10px;
            $min_width: 300px;
            $max_width: 1023px;
            $min_font: 20px;
            $max_font: 38px;
            @include fluid-type($min_width, $max_width, $min_font, $max_font);
          }
        }
        &:not(:last-child) {
          padding-right: $header-link-padding;
        }
      }
    }
  }
</style>
